.FooterComponent__brand {
  padding: 50px 0px;
  img {
    height: 32px;
  }
}
.FooterComponent__container {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
  }


  .social {
    flex-direction: row;
    img {
      padding: 5px;
      width: 30px;
      height: 30px;
    }
  }

  .social,
  .links {
    a {
      color: inherit;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .right {
    justify-content: flex-start;
  }

  
  .license {
    order: 1;
  }

  // Tablet and up
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;

    > div {
      flex: 50%;
    }

    .right {
      justify-content: flex-end;
    }

    .license {
      order: 0;
    }

  }
}

.FooterComponent__description {
  opacity: 0.5;
  > div {
    font-size: small;
    margin-bottom: 10px;
  }
}

.FooterComponent__title {
  opacity: 0.5;
  font-size: small;
  padding-bottom: 15px;
}