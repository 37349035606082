.LicenseSection {
    align-items: center;
    justify-content: flex-end;
}

.LicenseSection__column {
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.LicenseSection__image {
    width: 80px;
    height: 80px !important;
}

.LicenseSection__details {
    font-size: 12px;
    opacity: 0.6;
}

.LicenseSection__title {
    font-size: 12px;
}

@media (max-width: 1024px) {
    .LicenseSection {
        margin-top: 0px;
    }
    .LicenseSection__columns {
        padding-top: 10px;
        align-items: center;
        justify-content: center;
    }
    
    .LicenseSection__details {
        opacity: 0.6;
    }
    
    .LicenseSection__title {
        text-align: center;
    }
  }