.FaqItem {
  cursor: pointer;
  padding: 1.6rem 0;
  border-bottom: 1px solid #efefef;
  &:last-child {
    border-bottom: none;
  }
}

.FaqItem__icon {
  margin-right: 1rem;
}

.FaqItem__question {
  font-size: 1.125rem !important;
}

.FaqItem__answer {
  font-size: 1rem !important;
}