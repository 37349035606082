.ShareIcons__share {
    .share-title {
        font-size: 20px;
        font-family: 'AkkuratBold', sans-serif;
        font-weight: 600;
        margin-bottom: 20px;
        color: rgba(0,0,0,0.84);
    }

    .share-buttons img {
        width: 22px;
        height: 22px;
        margin-right: 20px;
        top: 0px;
    }

    .share-buttons-small img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        top: 0px;
    }
}
