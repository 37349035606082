.PerksSection__margin-top {
  margin-top: -5%;
}

.line-height-big {
  line-height: 1.4;
}

@media (max-width: 1024px) {
  .PerksSection__margin-top {
    margin-top: 0;
  }
}