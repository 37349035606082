.akkurat-font {
    font-family: 'AkkuratRegular', sans-serif;
  }
  
.akkurat-bold-font {
font-family: 'AkkuratBold', sans-serif;
}

.akkurat-light-font {
font-family: 'AkkuratLight', sans-serif;
}

.poppins-font {
font-family: 'Poppins', sans-serif;
}

.govest-bg-color {
background-color: #55E9BD !important;
}

.no-font-weight {
font-weight: normal !important;
}