.HeroSection__image {
  margin: 0 auto;
  max-width: 150%;
}

.HeroSection__margin-top {
  margin-top: -10%;
}

.normal-weight {
  font-weight: normal;
}

.padded {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  opacity: 0.6;
}

@media (max-width: 1024px) {
  .HeroSection__image {
    max-width: 110%;
  }

  .HeroSection__margin-top {
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .HeroSection__image {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .HeroSection__image {
    max-width: 100%;
  }
}