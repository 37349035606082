.form-button {
  color: #2e2e2e;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: small;
  border: none;
  outline: none;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
  max-width: 350px;
  margin-top: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.input-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.input-container-center {
  align-self: center;
  margin: auto;
}

.input-label {
  font-family: "AkkuratBold", sans-serif;
  color: rgba(0, 0, 0, 0.4);
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  font-size: 13px;
}

.input-text {
  font-family: "AkkuratRegular", sans-serif;
  color: #2e2e2e;
  width: 100%;
  border: 0px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 15px;
}

.input-text::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.input-text::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.input-text::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.input-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
