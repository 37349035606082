.Perks__icon {
    max-width: 30px;
    max-height: 30px;
}

.Perks__column {
    padding: 15px !important;
}

.Perks__column header {
    margin: 0px !important;
}