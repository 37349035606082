.logo-image {
  height: 46px;
}

.navbar-language-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.navbar-language-active {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.84) !important;
}

.navbar-language {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  height: fit-content;
  padding: 8px;
  border-radius: 5px;
  margin-right: 2px;
  width: 40px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}

.right-line {
  border-right: 1px solid rgba(0, 0, 0, 0.84);
}

@media (max-width: 991px) {
  .logo-image {
    height: 36px;
  }
}
