.TeamBios__card {
  flex-direction: column;
}

.TeamBios__card-content {
  flex-direction: column;
  height: 100%;
  padding: 1.8rem;
}

.TeamBios__avatar-wrapper {
  margin: 0 auto;
}

.TeamBios__details {
  margin-top: 20px;
}

.TeamBios__bio {
  margin-top: 20px;
}
