.BlogPost__disclosure {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 0px;
}

.BlogPost__column {
  max-width: 800px;
  padding: 20px;

  .title {
    font-family: 'AkkuratBold', sans-serif;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .subtitle {
    margin-bottom: 50px;
    opacity: 0.7;
  }

  .publisher_container {
    margin-bottom: 50px;
  }

  .publisher {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .publisher .author {
    display: flex;
    flex-direction: column;
  }

  .publisher .image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .publisher .author .name {
    font-size: 13px;
    font-weight: 400;
  }

  .publisher .author .date {
    font-size: 13px;
    opacity: 0.7;
    font-weight: 400;
  }

  .share {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.BlogPost__share {
  margin: 50px 0px;
}

.BlogPost__md {
  font-family: 'AkkuratRegular', sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.84);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'AkkuratBold', sans-serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.84);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  p,
  i {
    margin-top: 21px;
    font-family: 'AkkuratRegular', sans-serif;
    font-size: 20px;
    letter-spacing: -0.004em;
    line-height: 1.7;
    color: rgba(0, 0, 0, 0.84);
  }

  strong {
    font-family: 'AkkuratBold', sans-serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.84);
  }

  a {
    font-family: 'AkkuratBold', sans-serif;
    font-weight: 600;
    color: #21ce99;
  }

  blockquote {
    padding: 0 1em;
    color: #6a737d !important;
    border-left: 0.25em solid #dfe2e5;
    background: none;
  }

  code {
    color: #007bff;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: #f6f8fa;
    border-radius: 3px;
  }

  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }
  pre code {
    display: inline;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    border: 0;
  }

  a {
    font-family: 'Akkurat-Bold';
    font-weight: 600;
    color: #21ce99;
  }

  img {
    background-color: #fff;
    box-sizing: content-box;
    max-width: 100%;
  }

  img[align='right'] {
    padding-left: 20px;
  }

  img[align='left'] {
    padding-right: 20px;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  table th {
    font-weight: 600;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  hr {
    opacity: 0.3;
    margin: 30px auto;
    max-width: 100px;
    align-self: center;
    border-style: inset;
    border-width: 1px;
  }

  @media (max-width: 725px) {
    p,
    i {
      font-size: 18px;
    }

    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 23px;
    }
  }
}
