.BlogListSection {
  padding: 80px !important;
}

.BlogListSection__title {
    font-weight: 600;
    font-size: 30px;
    color: rgba(0,0,0,0.84);
}

.BlogListSection__subtitle {
  font-weight: 300;
  font-size: 20px;
  color: rgba(0,0,0,0.4);
}

.BlogListSection__columns {
  margin-top: 40px;
}

.BlogListSection__item {
    padding: 40px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 20px 20px rgba(0,0,0,0.03);
    -webkit-transition: all 0.1s; /* For Safari 3.1 to 6.0 */
    transition: all 0.1s;

    .title {
        font-weight: 600;
        font-size: 25px;
        color: rgba(0,0,0,0.84);
    }

    .date {
        font-size: 13px;
        margin-top: 10px;
        opacity: 0.4;
        color: rgba(0,0,0,0.84);
    }

    .subtitle {
        font-size: 15px;
        margin-top: 10px;
        height: 20px;
        text-overflow: ellipsis;
        color: rgba(0,0,0,0.84);
      
        /* Needed to make it work */
        overflow: hidden;
        white-space: nowrap;
    }
}

.BlogListSection__item:hover {
  margin-top: -5px;
  margin-bottom: 5px;
  box-shadow: 0px 20px 20px rgba(0,0,0,0.05);
}


@media (max-width: 725px) {
  .BlogListSection {
    padding: 40px !important;
  }
}