.VerificationStatusSection__title {
  color: #4F4F4F;
}

.VerificationStatusSection__subtitle {
  color: #BDBDBD;
}

.VerificationStatusSection__container {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .VerificationStatusSection__container {
    padding: 10%;
  }
}