.Features {
  // max-width: 900px;
  margin: 80px auto 0 auto;
}

.Features__columns {
  // Reverse every other row
  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }

  &:not(:last-of-type) {
    padding-bottom: 1.5rem;
    @media screen and (min-width: 769px) {
      padding-bottom: 2.5rem;
    }
  }
}

.Features__margin-top {
  margin-top: -5%;
}

.Features__title {
  margin-bottom: 1.2rem !important;
}

.Features__no-classname {
  // display: block;
  // max-width: 300px;
  // margin: 30px auto;
  margin: 0 auto;
  max-width: 100%;
}

.char-spacing {
  letter-spacing: 0.25em;
}

.Features__grey-color {
  opacity: 0.6;
}

.Features__400-text-weight {
  font-weight: 400 !important;
}

a {
  font-weight: 600 !important;
}
@media (max-width: 1024px) {
  .Features__no-classname {
    margin: 0 auto;
    max-width: 125%;
  }

  .Features__margin-top {
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .Features__no-classname {
    margin: 0 auto;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .Features__no-classname {
    margin: 0 auto;
    max-width: 100%;
  }
}