.GetAppSection__container {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-text {
  margin-top: 10px;
}

.referral-code {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 2px;
}

@media (max-width: 1024px) {
  .GetAppSection__container {
    padding: 10%;
  }
}