.EarlyAccessBanner {
    background-color: #22EEB1;
    margin: 0px !important;
}

.blackText {
    color: #0A0A0A;
}
.EarlyAccessBanner header {
    margin: 0px !important;
}

.EarlyAccessBanner__button {
    color: #2e2e2e;
    border-radius: 5px;
    background-color: white;
    padding: 15px 20px;
    font-size: small;
    border: none;
    outline: none;
    flex-grow: 1;
    cursor: pointer;
}