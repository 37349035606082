.BannerSection {
    background-color: #1B1823;
    background-image: linear-gradient(to right, #262433, #100F15);
    overflow: hidden;
}


.BannerSection__image {
    width: 80%;
    margin:0px;
    margin-top: 50px;
    float: center;
    align-self: center;
    overflow: auto;
}

.BannerSection__title {
    margin-top: 50px;
    font-weight: 600;
    color: white;
}

.BannerSection__subtitle {
    font-weight: 600;
    color: white;
    margin: 0 !important;
    padding: 0 !important;
}

.BannerSection__description {
    font-weight: 400;
    color: white;
}

@media (min-width: 768px) {
    .BannerSection__title {
        margin-top: 0px;
    }
    .BannerSection__image {
        width: 80%;
        margin: 20px 0px;
        float: right;
    }
  }