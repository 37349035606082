.Financials__logo {
  margin: 0 12px;
  img {
    // Removes extra space under image
    vertical-align: bottom;
  }
}

.Financials__box-image {
  width: 5%;
}

.Financials__margin-top {
  margin-top: 5%;
}

@media (max-width: 1024px) {
  .Financials__box-image {
    width: 20%;
  }
}

@media (max-width: 991px) {
  .Financials__box-image {
    width: 4%;
  }
}

@media (max-width: 767px) {
  .Financials__box-image {
    width: 8%;
  }
}